    /* Container to hold the scrolling images */

    .infinite-scroll-container {
        width: 100%;
        overflow: hidden;
        margin-top: 5rem;
        position: relative;
    }

    .image-scroll {
        display: flex;
        gap: 4rem;
        animation: scroll 20s linear infinite;
    }
        /* Image item styling */
    
        .image-item {
            flex: 0 0 auto;
            /* Ensures each image maintains its size */
        }
    
        .image-item img {
        height: 50px;
            width: auto;
        }
        /* Infinite scroll animation */
    
        @keyframes scroll {
            0% {
                transform: translateX(0);
            }
                /* Adjust the endpoint to fit the number of images in the container */
                100% {
                    transform: translateX(calc(-100% / 2));
                }
                }
        /* Responsive Styling */
        /* Large screens */
    
        @media (min-width: 1024px) {
            .image-scroll {
                gap: 4rem;
                animation: scroll 20s linear infinite;
            }
    
            .image-item img {
                height: 60px;
            }
        }
    
        /* Medium screens */
    
        @media (min-width: 768px) and (max-width: 1023px) {
            .image-scroll {
                gap: 3rem;
                animation: scroll 15s linear infinite;
            }
    
            .image-item img {
                height: 80px;
            }
        }
    
        /* Small screens */
    
        @media (max-width: 767px) {
            .image-scroll {
                gap: 2rem;
                animation: scroll 10s linear infinite;
            }
    
            .image-item img {
                height: 40px;
            }
        }